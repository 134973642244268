<script setup>
const { t } = useProductLocalization();

defineProps({
    locked: Boolean,
    isOpen: Boolean,
});

const PAGES = computed(() => [
    {
        label: t('pages.dashboard'),
        url: 'dashboard',
        icon: 'IconDashboard',
    },
    {
        label: t('pages.employees'),
        url: 'employees',
        icon: 'IconTeammember',
    },
    {
        label: t('pages.topics'),
        url: 'topic-catalog',
        icon: 'IconTopic',
    },
    // {
    //     label: t('pages.groups'),
    //     url: 'groups',
    //     icon: 'IconGroups',
    // },
]);
</script>

<template>
    <ul>
        <NavItem
            v-for="(nav, index) in PAGES"
            :key="`nav-${index}`"
            :locked="locked"
            :is-open="isOpen"
            :label="nav.label"
            :url="nav.url"
            :icon="nav.icon"
            :show="nav.show"
            @click="$emit('click:nav-item')"
        />
    </ul>
</template>
