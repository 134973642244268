<script setup>
const show = ref();

const { data: profile } = useAuth();

const open = () => {
    show.value = true;
};

const close = () => {
    show.value = false;
};

defineExpose({
    open,
    close,
});
</script>

<template>
    <CommonModal
        id="other-products-modal"
        v-model:show="show"
        name="other-products-modal"
        size="small"
    >
        <template #header>
            <div class="flex items-center">
                <h2 class="h2">
                    {{ $t('admin.other_products_header') }}
                </h2>
            </div>
        </template>

        <div>
            <div>
                <p>
                    <i18n-t
                        keypath="admin.other_products_description"
                        tag="p"
                        class="mb-0"
                    >
                        <template #phone>
                            <a
                                href="tel:0228/82057123"
                                class="font-bold hover:text-slate-400 underline"
                            >
                                0228/82057123.
                            </a>
                        </template>
                        <template #mail>
                            <a
                                :href="`mailto:${profile?.platform?.sales_email}`"
                                class="font-bold hover:text-slate-400 underline"
                                v-text="profile?.platform?.sales_email"
                            />
                        </template>
                        <template #productPage>
                            <a
                                href="https://www.skillsforwork.de"
                                class="font-bold hover:text-slate-400 underline"
                                target="_blank"
                            >
                                www.skillsforwork.de,
                            </a>
                        </template>
                    </i18n-t>
                </p>
            </div>
            <div class="flex justify-end mt-8">
                <CommonButton primary icon arrow @click="close">
                    {{ $t('admin.account.close') }}
                </CommonButton>
            </div>
        </div>
    </CommonModal>
</template>
