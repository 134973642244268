<script setup>
const show = ref();

const open = () => {
    show.value = true;
};

const close = () => {
    show.value = false;
};

defineExpose({
    open,
    close,
});
</script>

<template>
    <CommonModal
        id="no-product-access-modal"
        v-model:show="show"
        name="no-product-access-modal"
        size="small"
    >
        <template #header>
            <div class="flex items-center">
                <h2 class="h2">
                    {{ $t('admin.header.modal_title') }}
                </h2>
            </div>
        </template>

        <div>
            <div>
                <span v-text="$t('admin.header.no_product_access_long')" />
            </div>
            <div class="flex justify-end mt-8">
                <CommonButton primary icon arrow @click="close">
                    {{ $t('global.close') }}
                </CommonButton>
            </div>
        </div>
    </CommonModal>
</template>
