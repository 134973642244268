<script setup>
const { t } = useProductLocalization();

defineProps({
    locked: Boolean,
    isOpen: Boolean,
});

defineEmits(['click:nav-item']);

const PAGES = computed(() => [
    {
        label: t('pages.dashboard'),
        url: 'dashboard',
        icon: 'IconDashboard',
    },
    {
        label: t('pages.learning_path'),
        url: 'learning-path/main',
        icon: 'IconTopic',
    },
    {
        label: t('pages.employees'),
        url: 'employees',
        icon: 'IconTeammember',
    },
    {
        label: t('pages.groups'),
        url: 'groups',
        icon: 'IconGroups',
    },
]);
</script>

<template>
    <ul>
        <NavItem
            v-for="(nav, index) in PAGES"
            :key="`nav-${nav.url}-${index}`"
            :locked="locked"
            :is-open="isOpen"
            :label="nav.label"
            :url="nav.url"
            :icon="nav.icon"
            @click="$emit('click:nav-item')"
        />
    </ul>
</template>
