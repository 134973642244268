<script setup>
import ProductLink from './ProductLink.vue';

const component = ref();
useClickOutside(component, () => {
    if (showDropdown.value) {
        toggleDropdown();
    }
});

const storeProducts = useProductStore();
await useAsyncData('products', () => storeProducts.loadProducts());

const { products } = storeToRefs(storeProducts);

const { navigateToProduct } = useAdminUrl();

const showDropdown = ref(false);
const otherProductsModal = ref();

const noAccessModal = ref();
const toggleDropdown = () => {
    showDropdown.value = !showDropdown.value;
};

function clickedItem(productUuid) {
    storeProducts.setActive(productUuid);

    return navigateToProduct(productUuid, 'dashboard');
}

const clickedNoAccessItem = (productUuid) => {
    storeProducts.setActive(productUuid);

    return navigateToProduct(productUuid);
};

const clickedDisabledItem = () => {
    noAccessModal.value?.open();
};
</script>

<template>
    <!-- TODO check if we can switch to CommonMenu -->
    <div ref="component" class="relative">
        <button
            class="flex items-center justify-between border-x py-3 px-3 md:py-4 md:px-5 gap-3 md:gap-4 xl:gap-5 border-slate-300 transition-colors w-[300px] md:w-[340px] xl:w-[400px] 2xl:w-[450px]"
            @click.prevent="toggleDropdown"
        >
            <div
                class="flex items-center gap-3 md:gap-4 xl:gap-5 overflow-hidden"
            >
                <img
                    v-if="storeProducts.currentProduct?.logo_square?.src"
                    :src="storeProducts.currentProduct?.logo_square?.src"
                    class="h-8 w-8 shrink-0"
                />
                <div class="leading-none overflow-hidden text-left">
                    <span
                        class="uppercase font-bold text-xs text-slate-400 mb-0.5 opacity-40"
                    >
                        {{ $t('global.product') }}
                    </span>
                    <strong
                        class="tracking-tight leading-none whitespace-nowrap w-full truncate block"
                    >
                        {{ storeProducts.currentProduct?.name }}
                    </strong>
                </div>
            </div>
            <IconArrowTopbar class="shrink-0" />
        </button>

        <Transition>
            <div
                v-if="showDropdown"
                class="absolute top-full left-0 right-0 bg-white border-r border-y rounded-br shadow-sm border-slate-300 z-10 overflow-hidden"
            >
                <ul class="flex flex-col items-start">
                    <template v-if="products">
                        <ProductLink
                            v-for="product in products"
                            :key="product.uuid"
                            :product="product"
                            :active="product.isActive"
                            @click="clickedItem(product.uuid)"
                            @click-no-access="clickedNoAccessItem(product.uuid)"
                            @click-disabled="clickedDisabledItem(product.uuid)"
                        />
                    </template>
                    <li class="px-3 md:px-5 py-1 w-full">
                        <button
                            class="whitespace-nowrap truncate"
                            @click.prevent="otherProductsModal?.open()"
                        >
                            {{ $t('global.book_other_solutions') }}
                        </button>
                    </li>
                </ul>
            </div>
        </Transition>
        <ModalOtherProducts ref="otherProductsModal" />

        <ModalNoProductAccess ref="noAccessModal" />
    </div>
</template>
