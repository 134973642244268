<script setup>
import NavItem from './NavItem.vue';

const { canAccessCurrentProduct: hasProductAccess } = useUserPermissions();

const { urlPath, hasTeamOrProduct } = useAdminUrl();

defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(['click:nav-item']);

function clickedNavItem() {
    emit('click:nav-item', true);
}
</script>

<template>
    <ul class="flex flex-col gap-2 w-full px-8">
        <NavItem
            v-show="!hasTeamOrProduct"
            :is-open="isOpen"
            :label="$t('global.back_to_product')"
            :to="urlPath"
            icon="IconBack"
            @click="$router.back()"
        />
        <NavItem
            v-show="!hasProductAccess && hasTeamOrProduct"
            :is-open="isOpen"
            :label="$t('pages.home')"
            :to="urlPath"
            icon="IconBack"
            @click="clickedNavItem"
        />
    </ul>

    <template v-if="hasTeamOrProduct">
        <ProductTypeComponent>
            <template #fallback>
                <NavDefaultNavigation
                    :locked="!hasProductAccess"
                    :is-open="isOpen"
                    class="flex flex-col gap-2 w-full px-8"
                    @click:nav-item="clickedNavItem"
                />
            </template>

            <template #singleCuratedLearningPath>
                <NavSingleLearningPathNavigation
                    :locked="!hasProductAccess"
                    :is-open="isOpen"
                    class="flex flex-col gap-2 w-full px-8"
                    @click:nav-item="clickedNavItem"
                />
            </template>

            <template #personalUserTopics>
                <NavPersonalUserTopicsNavigation
                    :locked="!hasProductAccess"
                    :is-open="isOpen"
                    class="flex flex-col gap-2 w-full px-8"
                    @click:nav-item="clickedNavItem"
                />
            </template>
        </ProductTypeComponent>
    </template>
</template>
