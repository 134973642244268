<script setup>
const { canAccessCurrentProduct: hasProductAccess } = useUserPermissions();

const query = ref({
    channel: 'control-panel',
    all: false,
});

const { data: notifications } = usePolled('/api/v1/notifications', query, {
    fetchOnMount: true,
    allowEmpty: true,
});

defineEmits(['close:notification']);

const showPanel = ref(false);
const container = ref();

const markAsRead = (notificationId) => {
    const index = notifications.value.findIndex(
        (item) => item.uuid === notificationId
    );

    if (index === -1) return;

    notifications.value[index].read_at = new Date();
};

const unreadNotifications = computed(() => {
    return notifications.value?.filter((item) => item.read_at === null) ?? [];
});

useClickOutside(container, () => {
    showPanel.value = false;
});

const usedNotifications = computed(() => {
    return query.value.all ? notifications.value : unreadNotifications.value;
});
</script>

<template>
    <!-- TODO check if we can use CommonMenu -->
    <div ref="container">
        <CommonButton
            v-if="hasProductAccess"
            :class="{
                'sm:bg-slate-100 rounded text-slate-800': showPanel,
            }"
            class="flex sm:items-center sm:justify-center text-slate-400 hover:text-slate-800 relative p-0 pb-3 sm:p-1.5"
            @click.prevent="showPanel = !showPanel"
        >
            <template #custom-icon>
                <IconBell class="w-[25px] h-5 mr-4 sm:mr-0" color="#7A828F" />
                <span
                    v-if="unreadNotifications.length > 0"
                    class="rounded-full bg-theme-pink min-w-[1rem] h-4 leading-4 px-[3px] text-white text-xs font-bold absolute -top-2 sm:-top-1 left-2 sm:left-4"
                    v-text="unreadNotifications.length"
                />
            </template>
            <span class="sm:hidden font-bold text-sm text-slate-800">
                {{ $t('global.app_topbar.push_notifications') }}
            </span>
        </CommonButton>

        <Transition>
            <div
                v-if="showPanel"
                class="shadow-xl px-8 pt-6 bg-white absolute top-[calc(100%+0.5rem)] right-2 sm:right-4 w-[calc(100vw-2rem)] md:w-[400px] rounded-lg"
            >
                <div class="flex items-center justify-between mb-4">
                    <h3 class="h3 mb-0">{{ $t('global.notifications') }}</h3>
                    <button
                        class="-mt-px"
                        type="button"
                        aria-label="Close"
                        @click.prevent="showPanel = false"
                    >
                        <IconClose2 class="w-5 md:w-6" />
                    </button>
                </div>

                <div class="relative">
                    <TransitionGroup
                        name="list"
                        tag="ul"
                        class="-mx-8 border-t border-neutral-200 max-h-[500px] overflow-y-auto overflow-x-hidden"
                    >
                        <li
                            v-if="!usedNotifications.length"
                            class="px-8 py-6 border-b border-neutral-200"
                        >
                            <span
                                class="flex gap-3 text-left items-stretch w-full group"
                            >
                                {{ $t('global.app_topbar.no_notifications') }}
                            </span>
                        </li>
                        <li
                            v-for="item in usedNotifications"
                            :key="item.uuid"
                            class="border-b border-neutral-200 last:border-0 hover:bg-gray-50 transition-colors last:pb-2"
                        >
                            <TopbarNotificationItem
                                :uuid="item.uuid"
                                :headline="item.headline"
                                :message="item.message"
                                :type="item.type"
                                :channel="item.channel"
                                :has-read="!!item.read_at"
                                :actions="item.actions"
                                @read="markAsRead"
                            />
                        </li>
                    </TransitionGroup>
                </div>

                <div class="my-3">
                    <InputCheckbox
                        id="show-all-notifications"
                        v-model="query.all"
                        name="show-all-notifications"
                        :label="$t('admin.app_topbar.show_read_notifications')"
                    />
                </div>
            </div>
        </Transition>
    </div>
</template>

<style scoped>
.list-move,
.list-enter-active,
.list-leave-active {
    transition: all 0.4s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

.list-leave-active {
    position: absolute;
}
</style>
