<script setup>
import { useTeamsStore } from '~/stores/teams';

const { t } = useProductLocalization();

const storeTeams = useTeamsStore();

defineProps({
    locked: Boolean,
    isOpen: Boolean,
});

const PAGES = computed(() => [
    {
        label: t('pages.dashboard'),
        url: 'dashboard',
        icon: 'IconDashboard',
    },
    {
        label: t('pages.trainings'),
        url: 'trainings',
        icon: 'IconTrainings',
    },
    {
        label: t('pages.employees'),
        url: 'employees',
        icon: 'IconTeammember',
    },
    {
        label: t('pages.groups'),
        url: 'groups',
        icon: 'IconGroups',
    },
    {
        label: t('pages.topics'),
        url: 'topics',
        icon: 'IconTopic',
    },
    {
        label: t('pages.certificates'),
        url: 'certificates',
        icon: 'IconDocumentation',
    },
    {
        label: t('pages.certificate_archive'),
        url: 'certificate-archive',
        icon: 'IconCertificate',
        show: () => storeTeams.hasLegacyCertificates,
    },
]);
</script>

<template>
    <ul>
        <NavItem
            v-for="(nav, index) in PAGES"
            :key="`nav-${index}`"
            :locked="locked"
            :is-open="isOpen"
            :label="nav.label"
            :url="nav.url"
            :icon="nav.icon"
            :show="nav.show"
            @click="$emit('click:nav-item')"
        />
    </ul>
</template>
