<script setup>
const storeTeams = useTeamsStore();
const { t } = useI18n({ useScope: 'global' });

const selectStore = useSelectsStore();

const { countries, teamTypes } = storeToRefs(selectStore);
const { getCountries, getTeamTypes } = selectStore;

getCountries();
getTeamTypes();

const emit = defineEmits(['cancel:addTeam']);

const { value: formData } = useResetable({
    uuid: '',
    type: teamTypes.value?.[0]?.value ?? 1,
    name: '',
    display_name: '',
    contact_person: '',
    address_line_1: '',
    zip: '',
    city: '',
    country: 'de',
    onboarding_status: '',
    data_processing_contract: null,
    data_processing_accepted_at: null,
    sales_opt_in_accepted_at: null,
    index: null,
    isActive: false,
});

const show = ref(false);
const errors = ref(null);
const message = ref(null);

function cancelAddTeam() {
    emit('cancel:addTeam', '');
}

const { execute: submitForm } = useApiFetch(`/api/v1/teams`, '', {
    body: formData.value,
    method: 'POST',
    immediate: false,
    watch: false,
    onResponse({ response }) {
        if (!response.ok) {
            errors.value = response.error?.value?.data?.errors;
            return;
        }

        storeTeams.loadTeams();
        cancelAddTeam();
    },
});

const selectedTeamTypeLabel = computed(() => {
    if (!teamTypes.value) {
        return '';
    }

    return teamTypes.value.find((item) => item.value === formData.value?.type)
        ?.label;
});

const open = () => {
    show.value = true;
};

const close = () => {
    show.value = false;
    errors.value = null;
    message.value = null;
};

defineExpose({
    open,
    close,
});
</script>

<template>
    <CommonModal
        id="manage-locations-add-modal"
        name="manage-locations-add"
        :show="show"
        @close="close"
    >
        <template #header>
            <h2 class="h2">
                {{ $t('admin.account.add_company_or_location') }}
            </h2>
        </template>
        <div class="border-b border-slate-300">
            <div class="pb-6 mb-6">
                <MessagesSuccess v-if="message" class="mb-6">
                    <p>{{ message }}</p>
                </MessagesSuccess>
                <form id="submitTeam">
                    <div class="form-control-row">
                        <InputSelect
                            v-model="formData.type"
                            :label="$t('admin.account.type_of_client') + ' *'"
                            name="locations-add-type"
                            :options="teamTypes"
                        />
                    </div>
                    <div class="form-control-row">
                        <InputBase
                            v-model="formData.name"
                            :label="
                                (formData.type === 1
                                    ? $t('admin.company_name_legal')
                                    : $t('admin.location.name')) + ' *'
                            "
                            name="locations-add-name"
                            :placeholder="
                                formData.type === 1
                                    ? $t('admin.company_name_legal')
                                    : $t('admin.location.name')
                            "
                            :error="errors?.name"
                        />
                        <InputBase
                            v-if="formData.type === 1"
                            v-model="formData.display_name"
                            :label="$t('global.display_name')"
                            name="locations-add-display_name"
                            :placeholder="$t('global.display_name')"
                            type="text"
                        />
                    </div>
                    <div class="form-control-row">
                        <InputBase
                            v-model="formData.address_line_1"
                            :label="$t('admin.account.company_address')"
                            name="locations-add-address_line_1"
                            :placeholder="$t('admin.account.company_address')"
                        />
                    </div>
                    <div class="form-control-row">
                        <InputBase
                            v-model="formData.zip"
                            wrapper-class="md:w-1/4"
                            :label="$t('global.zip')"
                            name="locations-add-zip"
                            :placeholder="$t('global.zip')"
                        />
                        <InputBase
                            v-model="formData.city"
                            wrapper-class="md:w-3/4"
                            :label="$t('global.city')"
                            name="locations-add-city"
                            :placeholder="$t('global.city')"
                        />
                    </div>
                    <div class="form-control-row">
                        <InputSelect
                            v-model="formData.country"
                            :label="$t('global.country')"
                            name="locations-add-country"
                            :options="countries"
                        />
                    </div>
                </form>
            </div>
        </div>

        <div
            class="flex flex-col-reverse sm:flex-row gap-3 items-center justify-between mt-5"
        >
            <div
                class="flex items-center flex-col-reverse sm:flex-row gap-6 lg:gap-8 xl:gap-12"
            >
                <CommonButton link icon arrow @click.prevent="cancelAddTeam">
                    {{ $t('global.cancel') }}
                </CommonButton>
            </div>
            <CommonButton
                form="submitTeam"
                type="submit"
                primary
                icon
                arrow
                @click.prevent="submitForm"
            >
                {{ `${selectedTeamTypeLabel} ${t('global.create')}` }}
            </CommonButton>
        </div>
    </CommonModal>
</template>
