<script setup>
const storeTeams = useTeamsStore();
const { t } = useI18n({ useScope: 'global' });

const emit = defineEmits(['cancel:editTeam']);

const props = defineProps({
    currentTeamUuid: String,
});

const selectStore = useSelectsStore();

const { countries, teamTypes } = storeToRefs(selectStore);
const { getCountries, getTeamTypes } = selectStore;

getCountries();
getTeamTypes();

// TODO this component looks, smells and feels like wtf is going on here, REFACTOR!!!

const isActiveTeam = computed(() => {
    return formData.value?.uuid === props.currentTeamUuid;
});

const { value: formData, fill: fillForm } = useResetable({});

const show = ref(false);
const errors = ref(null);
const message = ref(null);

const isMainTeam = computed(() => {
    return formData.value?.is_main ?? false;
});

const selectedTeamTypeLabel = computed(() => {
    if (!teamTypes.value || !formData.value?.type) {
        return '';
    }

    return teamTypes.value.find((item) => item.value === formData.value?.type)
        ?.label;
});

function cancelEditTeam() {
    emit('cancel:editTeam', '');
}

const url = computed(() => {
    return `/api/v1/teams/${formData.value?.uuid}`;
});

const { execute: submitDelete, status: deleteStatus } = useApiFetch(url, '', {
    method: 'DELETE',
    immediate: false,
    watch: false,
    watchUrl: true,
    onResponse({ response }) {
        if (!response.ok) {
            return;
        }

        storeTeams.loadTeams();
        showDeleteMsg.value = false;
        cancelEditTeam();
    },
});

const { execute: submitForm } = useApiFetch(url, '', {
    body: formData.value,
    method: 'PATCH',
    immediate: false,
    watch: false,
    watchUrl: true,
    onResponse({ response }) {
        if (!response.ok) {
            errors.value = response.error?.value?.data?.errors;
            return;
        }

        storeTeams.loadTeams();
        showDeleteMsg.value = false;
        message.value = t('admin.teams.updated_successfully');
        setTimeout(() => {
            message.value = null;
            cancelEditTeam();
        }, 3000);
    },
});

const showDeleteMsg = ref(false);

function toggleDelete() {
    showDeleteMsg.value = !showDeleteMsg.value; // toggle the value of showDeleteMsg
}

const open = (team) => {
    fillForm(team);
    show.value = true;
};

const close = () => {
    show.value = false;
    errors.value = null;
    message.value = null;
    showDeleteMsg.value = false;
};

defineExpose({
    open,
    close,
});
</script>

<template>
    <CommonModal
        id="manage-locations-edit-modal"
        name="manage-locations-edit"
        :show="show"
        @close="close"
    >
        <template #header>
            <h2 class="h2">
                {{ $t('admin.account.edit_company_location') }}
            </h2>
        </template>
        <div class="border-b border-slate-300">
            <div class="pb-6 mb-6">
                <MessagesSuccess v-if="message" class="mb-6">
                    <p>{{ message }}</p>
                </MessagesSuccess>
                <form id="submitTeam">
                    <div class="form-control-row">
                        <InputSelect
                            v-model="formData.type"
                            :label="$t('admin.account.type_of_client') + ' *'"
                            name="locations-edit-type"
                            :options="teamTypes"
                        />
                    </div>
                    <div class="form-control-row">
                        <InputBase
                            v-model="formData.name"
                            :label="
                                (formData.type === 1
                                    ? $t('admin.company_name_legal')
                                    : $t('admin.location.name')) + ' *'
                            "
                            name="locations-edit-name"
                            :placeholder="
                                formData.type === 1
                                    ? $t('admin.company_name_legal')
                                    : $t('admin.location.name')
                            "
                            :error="errors?.name"
                        />
                        <InputBase
                            v-if="formData.type === 1"
                            v-model="formData.display_name"
                            :label="$t('global.display_name')"
                            name="locations-edit-display_name"
                            :placeholder="$t('global.display_name')"
                            type="text"
                        />
                    </div>
                    <div class="form-control-row">
                        <InputBase
                            v-model="formData.address_line_1"
                            :label="$t('admin.account.company_address')"
                            name="locations-edit-address_line_1"
                            :placeholder="$t('admin.account.company_address')"
                        />
                    </div>
                    <div class="form-control-row">
                        <InputBase
                            v-model="formData.zip"
                            wrapper-class="md:w-1/4"
                            :label="$t('global.zip')"
                            name="locations-edit-zip"
                            :placeholder="$t('global.zip')"
                        />
                        <InputBase
                            v-model="formData.city"
                            wrapper-class="md:w-3/4"
                            :label="$t('global.city')"
                            name="locations-edit-city"
                            :placeholder="$t('global.city')"
                        />
                    </div>
                    <div class="form-control-row">
                        <InputSelect
                            v-model="formData.country"
                            :label="$t('global.country')"
                            name="locations-edit-country"
                            :options="countries"
                        />
                    </div>
                </form>
            </div>
        </div>

        <div
            class="flex flex-col-reverse sm:flex-row gap-3 items-center justify-between mt-5"
        >
            <div
                class="flex items-center flex-col-reverse sm:flex-row gap-6 lg:gap-8 xl:gap-12"
            >
                <CommonButton link icon arrow @click.prevent="cancelEditTeam">
                    {{ $t('global.cancel') }}
                </CommonButton>
                <CommonButton
                    v-if="!isMainTeam && !showDeleteMsg && !isActiveTeam"
                    link
                    icon
                    class="text-theme-pink hover:text-theme-pink/80"
                    @click.prevent="toggleDelete"
                >
                    <template #custom-icon>
                        <IconTrash />
                    </template>
                    {{ $t('global.delete_entry') }}
                </CommonButton>
                <div v-if="showDeleteMsg">
                    <div
                        class="pr-4 pb-2 text-theme-pink text-center sm:text-left text-sm"
                    >
                        {{ $t('global.confirm_delete_team') }}
                        <strong>{{ formData.name }}</strong>
                    </div>
                    <div class="flex justify-center sm:justify-start">
                        <CommonButton
                            link
                            icon
                            small
                            arrow
                            :disabled="deleteStatus === 'pending'"
                            @click.prevent="toggleDelete"
                        >
                            {{ $t('global.no') }}
                        </CommonButton>
                        <CommonButton
                            icon
                            primary
                            small
                            class="ml-4"
                            :loading="deleteStatus === 'pending'"
                            :disabled="deleteStatus === 'pending'"
                            @click.prevent="submitDelete"
                        >
                            <template #custom-icon>
                                <IconTrash />
                            </template>
                            {{ $t('global.yes') }}
                        </CommonButton>
                    </div>
                </div>
            </div>
            <CommonButton
                form="submitTeam"
                type="submit"
                primary
                icon
                arrow
                @click.prevent="submitForm"
            >
                {{ `${selectedTeamTypeLabel} ${t('global.save')}` }}
            </CommonButton>
        </div>
    </CommonModal>
</template>
