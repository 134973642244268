<script setup>
const props = defineProps({
    type: {
        type: Number,
        required: true,
    },
    url: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
});

const emit = defineEmits(['clicked']);

const { urlPath } = useAdminUrl();

const onClick = () => {
    if (props.type === 'external') {
        window.open(props.url, '_blank');
        emit('clicked');
        return;
    }

    if (props.type === 'view') {
        navigateTo(`${urlPath.value}/${props.url}`);
        emit('clicked');
        return;
    }
};
</script>

<template>
    <a class="text-sm leading-tight" @click.prevent.stop="onClick">
        {{ label }}
    </a>
</template>
